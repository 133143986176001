.root {
	color: #C7C7C8;
	background-color: #FFFFFF;
	border-right: 1px solid #ECECEC;
	box-sizing: border-box;
}

.root a {
	color: #8C8C8E;
}

.root a:hover {
	color: black;
}

