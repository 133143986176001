.activity {
    &::-webkit-scrollbar-thumb {
        background: #ececec;
        border-radius: 20px;
        border: 4px solid white;
    }
    &::-webkit-scrollbar {
        background: transparent;
    }
}

