@import 'mixins';
@import 'variables';

.Toastify__toast {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
	min-height: auto;
	padding: 10px 15px;
	background: $purple;
	color: white;
	opacity: 0.85;
	font-size: 14px;
	@include round-corner(5px);

	a {
		color: white;
	}

	&--warning {
		background-color: scale-color($red, $lightness: 40%);
	}
}